import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import { OrderItemFulfill } from "common-ts/dist/models/WorkBatchCut";

interface OrderItemFulfillListState {
    orderItemFulfillList: OrderItemFulfill[],
  
    status: APIRequestStatus,
    error: string | null,
}

const initialState : OrderItemFulfillListState = {
    orderItemFulfillList: [],

    status: APIRequestStatus.Idle,
    error: null
}

export const fetchOrderItemFulfillList = createAsyncThunk('orderItemFulfillList/fetchOrderItemFulfillList', async () => {
    const response = await axios({
        method: 'get',
        url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/work-order-item-fulfill',
    });
    return response.data
})

export const orderItemFulfillSlice = createSlice({
    name: 'orderItemFulfillList',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
      builder
        .addCase(fetchOrderItemFulfillList.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
        })
        .addCase(fetchOrderItemFulfillList.fulfilled, (state, action) => {
            state.orderItemFulfillList = action.payload.recordList;

            state.status = APIRequestStatus.Success;
        })
        .addCase(fetchOrderItemFulfillList.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            // state.error = action.error.message
            console.log("fetch is failure");
        })
    }
})

export default orderItemFulfillSlice.reducer