import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { fetchWorkBatchCutList } from "./WorkBatchCutListRedux";

interface WorkBatchCutPostState {
  dialogOpen: boolean
  dialogOpenUpdate: boolean

  updateDraft: any

  status: APIRequestStatus
  error: string
}

const initialState: WorkBatchCutPostState = {
    dialogOpen: false,
    dialogOpenUpdate: false,

    updateDraft: {},

    status: APIRequestStatus.Idle,
    error: ''
}

const workBatchCutPostSlice = createSlice({
    name: 'workBatchCutPost',
    initialState,
    reducers: {
        updateDialogOpen(state, action) {
            state.dialogOpen = action.payload;
        },
        updateDialogOpenUpdate(state, action) {
            state.dialogOpenUpdate = action.payload;
        },
        updateValUpdateDraft(state, action) {
            state.updateDraft = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postNewWorkBatchCut.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postNewWorkBatchCut.fulfilled, (state, action) => {
            state.status = APIRequestStatus.Success;

            state.dialogOpen = false;
          })
          .addCase(postNewWorkBatchCut.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("fetch is failure");
          })
          .addCase(postUpdateWorkBatchCut.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postUpdateWorkBatchCut.fulfilled, (state, action) => {
            state.status = APIRequestStatus.Success;

            state.dialogOpenUpdate = false;
          })
          .addCase(postUpdateWorkBatchCut.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("fetch is failure");
          })
      }
})

export const postNewWorkBatchCut = createAsyncThunk('workBatchCutPost/postNewWorkBatchCut', async (arg: any, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/work-batch-cut',
      data: arg
    });

    thunkAPI.dispatch(fetchWorkBatchCutList());

    return response.data
})

export const postUpdateWorkBatchCut = createAsyncThunk('workBatchCutPost/postUpdateWorkBatchCut', async (arg: any, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/work-batch-cut',
      data: {
        ...arg,
      }
    });

    thunkAPI.dispatch(fetchWorkBatchCutList());

    return response.data
})

export const { updateDialogOpen, updateDialogOpenUpdate, updateValUpdateDraft } = workBatchCutPostSlice.actions

export default workBatchCutPostSlice.reducer