import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import axios from 'axios';
import { fetchOrderItemFulfillList } from "./OrderItemFulfillListRedux";

interface OrderItemFulfillPostState {
  dialogOpen: boolean
  dialogOpenUpdate: boolean

  updateDraft: any

  status: APIRequestStatus
  error: string
}

const initialState: OrderItemFulfillPostState = {
    dialogOpen: false,
    dialogOpenUpdate: false,

    updateDraft: {},

    status: APIRequestStatus.Idle,
    error: ''
}

const orderItemFulfillPostSlice = createSlice({
    name: 'orderItemFulfillPost',
    initialState,
    reducers: {
        updateDialogOpen(state, action) {
            state.dialogOpen = action.payload;
        },
        updateDialogOpenUpdate(state, action) {
            state.dialogOpenUpdate = action.payload;
        },
        updateValUpdateDraft(state, action) {
            state.updateDraft = action.payload;
        }
    },
    extraReducers(builder) {
        builder
          .addCase(postNewOrderItemFulfill.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postNewOrderItemFulfill.fulfilled, (state, action) => {
            state.status = APIRequestStatus.Success;

            state.dialogOpen = false;
          })
          .addCase(postNewOrderItemFulfill.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("fetch is failure");
          })
          .addCase(postUpdateOrderItemFulfill.pending, (state, action) => {
            state.status = APIRequestStatus.RequestInProgress;
          })
          .addCase(postUpdateOrderItemFulfill.fulfilled, (state, action) => {
            state.status = APIRequestStatus.Success;

            state.dialogOpenUpdate = false;
          })
          .addCase(postUpdateOrderItemFulfill.rejected, (state, action) => {
            state.status = APIRequestStatus.Failure;
            console.log("fetch is failure");
          })
      }
})

export const postNewOrderItemFulfill = createAsyncThunk('orderItemFulfillPost/postNewOrderItemFulfill', async (arg: any, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/work-order-item-fulfill',
      data: arg
    });

    thunkAPI.dispatch(fetchOrderItemFulfillList());

    return response.data
})

export const postUpdateOrderItemFulfill = createAsyncThunk('orderItemFulfillPost/postUpdateOrderItemFulfill', async (arg: any, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const response = await axios({
      method: 'post',
      url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/work-order-item-fulfill',
      data: {
        ...arg,
      }
    });

    thunkAPI.dispatch(fetchOrderItemFulfillList());

    return response.data
})

export const { updateDialogOpen, updateDialogOpenUpdate, updateValUpdateDraft } = orderItemFulfillPostSlice.actions

export default orderItemFulfillPostSlice.reducer