import { configureStore } from '@reduxjs/toolkit';
import productReducer from './redux/ProductListRedux';
import createProductReducer from "./redux/ProductPostRedux";
import updateProductReducer from "./redux/ProductUpdatePostRedux";
import postNewClothReducer from "./redux/ClothPostRedux";
import { orderSlice } from './redux/OrderListRedux';
import { inventoryClothRequestSlice } from './redux/InventoryClothRequestRedux';
import { clothSlice } from './redux/ClothListRedux';
import { clothPieceSlice } from './redux/ClothPieceListRedux';
import clothPieceCreateReducer from './redux/ClothPiecePostRedux';
import orderUpdateReducer from './redux/OrderPostRedux';
import orderPaymentUpdateReducer from './redux/OrderPaymentPostRedux';
import unitItemListReducer from './redux/UnitItemListPostRedux';
import unitItemPostReducer from './redux/UnitItemPostRedux';
import unitItemUpdatePostReducer from './redux/UnitItemUpdatePostRedux';
import unitItemStockAddPostReducer from './redux/UnitItemStockAddPostRedux';
import unitItemStockTakePostReducer from './redux/UnitItemStockTakePostRedux';
import midstockListReducer from './redux/MidstockListPostRedux';
import midstockPostReducer from './redux/MidstockPostRedux';
import midstockUpdatePostReducer from './redux/MidstockUpdatePostRedux';
import midstockStockAddPostReducer from './redux/MidstockStockAddPostRedux';
import midstockStockTakePostReducer from './redux/MidstockStockTakePostRedux';
import FileListReducer from './redux/FileListRedux';
import createFileReducer from './redux/FilePostRedux';
import userListReducer from './redux/UserListRedux';
import serviceTicketListReducer from './redux/ServiceTicketListRedux'
import serviceTicketPostReducer from './redux/ServiceTicketPostRedux';
import serviceTicketUpdatePostReducer from './redux/ServiceTicketUpdatePostRedux';
import categoryReviewListReducer from './redux/CategoryReviewListRedux';
import categoryReviewPostReducer from './redux/CategoryReviewPostRedux';
import categoryReviewUpdatePostReducer from './redux/CategoryReviewUpdatePostRedux'
import collectionListReducer from './redux/CollectionListRedux';
import collectionUpdatePostReducer from './redux/CollectionUpdatePostRedux';
import productAnalyticsListReducer from './redux/ProductAnalyticsListRedux';
import workBatchCutListReducer from './redux/WorkBatchCutListRedux';
import workBatchCutPostReducer from './redux/WorkBatchCutPostRedux';
import orderItemFulfillListReducer from './redux/OrderItemFulfillListRedux';
import orderItemFulfillPostReducer from './redux/OrderItemFulfillPostRedux';

const store = configureStore({
  reducer: {
    productsList: productReducer,
    productCreate: createProductReducer,
    productUpdate: updateProductReducer,
    ordersList: orderSlice.reducer,
    inventoryRequestList: inventoryClothRequestSlice.reducer,
    clothsList: clothSlice.reducer,
    clothPieceList: clothPieceSlice.reducer,
    clothPieceCreate: clothPieceCreateReducer,
    postNewCloth: postNewClothReducer,
    orderUpdate: orderUpdateReducer,
    orderPaymentUpdate: orderPaymentUpdateReducer,
    unitItemList: unitItemListReducer,
    unitItemCreate: unitItemPostReducer,
    unitItemUpdate: unitItemUpdatePostReducer,
    unitItemStockAdd: unitItemStockAddPostReducer,
    unitItemStockTake: unitItemStockTakePostReducer,
    midstockList: midstockListReducer,
    midstockCreate: midstockPostReducer,
    midstockUpdate: midstockUpdatePostReducer,
    midstockStockAdd: midstockStockAddPostReducer,
    midstockStockTake: midstockStockTakePostReducer,
    fileList: FileListReducer,
    fileCreate: createFileReducer,
    userList: userListReducer,
    serviceTicketList: serviceTicketListReducer,
    serviceTicketCreate: serviceTicketPostReducer,
    serviceTicketUpdate: serviceTicketUpdatePostReducer,
    categoryReviewList: categoryReviewListReducer,
    categoryReviewCreate: categoryReviewPostReducer,
    categoryReviewUpdate: categoryReviewUpdatePostReducer,
    collectionList : collectionListReducer,
    collectionUpdate: collectionUpdatePostReducer,
    productAnalyticsList: productAnalyticsListReducer,
    workBatchCutList: workBatchCutListReducer,
    workBatchCutPost: workBatchCutPostReducer,
    orderItemFulfillList: orderItemFulfillListReducer,
    orderItemFulfillPost: orderItemFulfillPostReducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch