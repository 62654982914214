import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';

import { useAppSelector, useAppDispatch } from './reduxhooks';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { fetchProductsList } from './redux/ProductListRedux';
import { fetchInventoryClothRequest } from './redux/InventoryClothRequestRedux';
import { fetchOrdersList } from './redux/OrderListRedux';
import { fetchClothsList } from './redux/ClothListRedux';
import { fetchClothPiecesList } from './redux/ClothPieceListRedux';
import { fetchUnitItemsList } from './redux/UnitItemListPostRedux';
import { fetchFilesList } from './redux/FileListRedux';
import { fetchMidstockList } from './redux/MidstockListPostRedux';
import { fetchUserList } from './redux/UserListRedux';
import { fetchServiceTicketList } from './redux/ServiceTicketListRedux';
import { fetchCategoryReviewList } from './redux/CategoryReviewListRedux';
import { fetchWorkBatchCutList } from './redux/WorkBatchCutListRedux';
import { fetchOrderItemFulfillList } from './redux/OrderItemFulfillListRedux';

function App() {
  const content = useRoutes(router);
  const productsListStatus = useAppSelector(state => state.productsList.status);
  const inventoryRequestStatus = useAppSelector(state => state.inventoryRequestList.status);
  const ordersListStatus = useAppSelector(state => state.ordersList.status);
  const clothsListStatus = useAppSelector(state => state.clothsList.status);
  const clothPiecesListStatus = useAppSelector(state => state.clothPieceList.status);
  const unitItemsListStatus = useAppSelector(state => state.unitItemList.status);
  const midstocksListStatus = useAppSelector(state => state.midstockList.status);
  const filesListStatus = useAppSelector(state => state.fileList.status);
  const userListStatus = useAppSelector(state => state.userList.status);
  const serviceTicketStatus = useAppSelector(state => state.serviceTicketList.status);
  const workBatchCutStatus = useAppSelector(state => state.workBatchCutList.status);
  const categoryReviewStatus = useAppSelector(state => state.categoryReviewList.getStatus)
  const collectionListStatus = useAppSelector(state => state.collectionList.status);
  const orderItemFulfillStatus = useAppSelector(state => state.orderItemFulfillList.status);
  const dispatch = useAppDispatch();

  if (productsListStatus === APIRequestStatus.Idle) {
    console.log("start fetching products list");
    dispatch(fetchProductsList());
  }
  
  if (inventoryRequestStatus === APIRequestStatus.Idle) {
    console.log("start fetching inventory requests list");
    dispatch(fetchInventoryClothRequest());
  }

  if (ordersListStatus === APIRequestStatus.Idle) {
    console.log("start fetching orders list");
    dispatch(fetchOrdersList(""));
  }
  
  if (clothsListStatus === APIRequestStatus.Idle) {
    console.log("start fetching cloths list");
    dispatch(fetchClothsList());
  }

  if (clothPiecesListStatus === APIRequestStatus.Idle) {
    console.log("start fetching cloths list");
    dispatch(fetchClothPiecesList());
  }

  if (unitItemsListStatus === APIRequestStatus.Idle) {
    console.log("start fetching unit items list");
    dispatch(fetchUnitItemsList());
  }

  if (userListStatus === APIRequestStatus.Idle) {
    console.log("start fetching user list");
    dispatch(fetchUserList());
  }

  if(midstocksListStatus === APIRequestStatus.Idle){
    console.log("start fetching midstock list")
    dispatch(fetchMidstockList())
  }

  if(filesListStatus === APIRequestStatus.Idle){
    console.log("start fetching files list")
    dispatch(fetchFilesList())
  }

  if(serviceTicketStatus === APIRequestStatus.Idle){
    console.log("start fetching service ticket list")
    dispatch(fetchServiceTicketList())
  }

  if(categoryReviewStatus === APIRequestStatus.Idle){
    console.log("start fetching category review list")
    dispatch(fetchCategoryReviewList())
  }

  if(workBatchCutStatus === APIRequestStatus.Idle){
    console.log("start fetching category review list")
    dispatch(fetchWorkBatchCutList())
  }

  if(orderItemFulfillStatus === APIRequestStatus.Idle){
    console.log("start fetching category review list")
    dispatch(fetchOrderItemFulfillList())
  }

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
